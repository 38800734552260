import React from 'react';
import { motion } from 'framer-motion';
/* import { Helmet } from 'react-helmet'; */
import image from '../../media/jim5.png';
import ornament from '../../media/ornament.svg';
import './home.css';
import { opacityUpMin, opacityDownMin, opacityLeft, opacityRight, imgOpacity } from '../utils/framerConfig';

const Home = () => {
  const frontend = ['HTML5', 'CSS3', 'Javascript', 'React', 'UX', 'UI', 'NodeJS']

  return (
    <div className="main-container-home">
      <div className="two-column-left">
        <motion.div
          variants={opacityUpMin(0.2)} 
          initial="init"
          animate="animate"
          exit="init"
        >
          <img className="ornament" src={ornament} alt="ornament" />
        </motion.div>
        <motion.h1 
          className="home-h1"
          variants={opacityUpMin(0.4)} 
          initial="init"
          animate="animate"
          exit="init"
        >
          Hi! I´m Jim!
        </motion.h1>
        <motion.h3
          className="home-h3"
          variants={opacityDownMin(1)} 
          initial="init"
          animate="animate"
          exit="init"
        >
          A Frontend developer
        </motion.h3>
        <motion.p
          style={{marginTop: "4rem"}}
          variants={opacityRight(1.3)} 
          initial="init"
          animate="animate"
          exit="init"
        >
          Summary

        </motion.p>
        <div className="develop-container">
          {frontend.map((front, i) => {
            return (
              <motion.div 
                key={i} 
                className="develop-item"
                variants={opacityLeft(1.5)} 
                initial="init"
                animate="animate"
                exit="init"
              >
                {front}
              </motion.div>
            )
          })}
        </div>
      </div>
      <div className="two-column-right">
        <motion.img 
          className="image" 
          src={image} alt="Jim"
          variants={ imgOpacity(0.5) }
          initial="init"
          animate="animate"
          exit="init"
          >
        </motion.img> 
      </div>
    </div>
  )
}

export default Home;
