import React from 'react';
import { NavLink } from 'react-router-dom';
import close from '../../media/close.svg';
import './sidebar.css';

const Sidebar = ({ routes, open, setOpen }) => {
  return (
    <div className={`sidebar-container ${open ? 'sidebar-visible' : null}`}>
      <div onClick={() => setOpen(!open)}>
        <img src={close} className="sidebar-close" alt="close" />
      </div>
      <div className="sidebar-buttons-container">
        {routes.map(route => {
          return (
            <NavLink to={route.link} key={route.text} activeClassName="sidebar-active" onClick={() => setOpen(!open)}>
                <button className="sidebar-button-item">{route.text}</button>
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

export default Sidebar;
