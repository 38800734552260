import React from 'react';
import { motion } from 'framer-motion';
import linkedin from '../../media/linkedin.svg';
import mail from '../../media/mail.svg';
import github from '../../media/github.svg';
import './contactBar.css'

const ContactBar = () => {
  return (
    <div className="contactbar-main-container">
      <a href="mailto:nervalljim@gmail.com">
        <motion.img src={ mail } alt="mail icon" className="contactbar-icon-mail" 
        whileHover={{ scale: 1.1 }}
      />
      </a>
      <a href="https://www.linkedin.com/in/jimnervall/" target="_blank" rel="noopener noreferrer">
        <motion.img src={ linkedin } alt="linkedin icon" className="contactbar-icon-linkedin" 
          whileHover={{ scale: 1.1 }}
        />
        </a>
      <a href="https://github.com/Nervall" target="_blank" rel="noopener noreferrer">
        <motion.img src={ github } alt="github icon" className="contactbar-icon-github" 
          whileHover={{ scale: 1.1}}
        />
        </a>
    </div>
  )
}

export default ContactBar;
