import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import menu from '../../media/menu.svg';
import './header.css';

const Header = ({ routes, open, setOpen }) => {
  return (
    <>
      <nav className="navbar">
        {routes.map(route => {
          return (
            <NavLink className="navlink-wrapper" key={route.link} exact to={route.link} activeClassName="navlink-active">
              <button className="navlink-button">{route.text}</button>
            </NavLink>
          )
        })}
      </nav>
      <motion.div className="header-menu-container" onClick={() => setOpen(!open)}
        whileHover={{ scale: 1.1 }}
      >
        <img src={menu} className="header-menu" alt="menu" />
      </motion.div>
    </>
  )
}

export default Header;
