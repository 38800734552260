import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion'
import { Switch, Route, useLocation } from 'react-router-dom';
import Home from './pages/home/home';
import Header from './pages/header/header';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Projects from './pages/projects/projects';
import Cv from './pages/cv/cv';
import Sidebar from './pages/header/sidebar';
import ContactBar from './pages/contactBar/contactBar';
import './App.css';

function App() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  let routes = [
    { text: "Home", link: "/" },
    { text: "About", link: "/about" },
    { text: "CV", link: "/cv" },
    { text: "Projects", link: "/projects" },
    { text: "Contact", link: "/contact" }, 
 ];

 console.log(location)

  return (
    <>
      <Sidebar routes={routes} open={open} setOpen={setOpen} />
      <Header routes={routes} open={open} setOpen={setOpen} />
      <ContactBar />
      <AnimatePresence>
        <Switch location={location} key={location.key}>
          <Route path="/projects" component={Projects} />
          <Route path="/cv" component={Cv} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/" component={Home} />
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
