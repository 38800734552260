import React from 'react';
import { motion } from 'framer-motion';
import { opacityUp } from '../utils/framerConfig';
import connect4 from '../../media/connect4.jpg';
import quiz from '../../media/quiz.png';
import ommej from '../../media/ommej.png';
import trackit from '../../media/trackit.jpg';
import ux from '../../media/ux.png';
import github from '../../media/github.svg';
import eye from '../../media/eye.svg';
import './projects.css';

const Projects = () => {
  return (
    <div className="main-container">
      <div className="three-column-standard">
        <div className="project-main-container">
        <motion.div 
          className="project-container" 
          variants={opacityUp(0.4)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
            <img src={ommej} className="project-image" alt="OmMej - project"></img>
            <h4>Om Mej</h4>
            <p>As an intern at Knowit I was responsibly of Frontend in this project. Made a administration site for a company named Om Mej, where you could add different units and roles.</p>
            <div className="dev-container">
              <div className="dev-items">HTML</div>
              <div className="dev-items">CSS</div>
              <div className="dev-items">Javascript</div>
              <div className="dev-items">UX</div>
              <div className="dev-items">ReactJS</div>
              <div className="dev-items">Redux</div>
              <div className="dev-items">ContextApi</div>
              <div className="dev-items">Git</div>
              <div className="dev-items">Github</div>
              <div className="dev-items">chartJS</div>
              <div className="dev-items">RBAC</div>
            </div>
            <div className="icon-link-container">
              <div className="link-container">
                <img src={github} className="project-icon" alt="Github icon"/>
                <span>Could not link to this due to bussiness agreement</span>
              </div>
              <div className="link-container">
                <img src={eye} className="project-icon" alt="Eye icon"/>
                <span>Could not link to this due to bussiness agreement</span>
              </div>
            </div>
          </motion.div>
        </div>
        <motion.div 
          className="project-container" 
          variants={opacityUp(0.5)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <img src={quiz} className="project-image" alt="Quiz"></img>
          <h4>Quiz Master</h4>
          <p>A quiz app made in Vanilla Javascript. The application is made to work for people who needs screenreader, and for users who prefer to only use the keyboard. The questions is from the api "Open Trivia Database".</p>
          <div className="dev-container">
            <div className="dev-items">HTML</div>
            <div className="dev-items">CSS</div>
            <div className="dev-items">Javascript</div>
            <div className="dev-items">REST-Api</div>
            <div className="dev-items">UX</div>
            <div className="dev-items">Material Design</div>
          </div>
          <div className="icon-link-container">
            <div className="link-container">
              <img src={github} className="project-icon" alt="Github icon"/>
              <span><a href="https://github.com/Nervall/Mandatory-uxdesign2" target="_blank" rel="noopener noreferrer">Github Repo</a> </span>
            </div>
            <div className="link-container">
              <img src={eye} className="project-icon" alt="Eye icon"/>
              <span><a href="https://quiz-master-7fcb9.firebaseapp.com/" target="_blank" rel="noopener noreferrer">Visit</a> </span>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="three-column-standard">
        <div className="project-main-container">
          <motion.div 
            className="project-container" 
            variants={opacityUp(0.6)}
            initial="init" 
            animate="animate" 
            exit="init"
            >
            <img src={trackit} className="project-image" alt="Trackit - project"></img>
            <h4>Trackit</h4>
            <p>As an intern at Knowit I was responsibly of Frontend together with a classmate in this project. We made an app to keep track on developers registred time. We made questions to Jira and Tempo</p>
            <div className="dev-container">
              <div className="dev-items">HTML</div>
              <div className="dev-items">CSS</div>
              <div className="dev-items">Javascript</div>
              <div className="dev-items">UX</div>
              <div className="dev-items">ReactJS</div>
              <div className="dev-items">Redux</div>
              <div className="dev-items">Git</div>
              <div className="dev-items">Github</div>
              <div className="dev-items">chartJS</div>
              <div className="dev-items">RBAC</div>
              <div className="dev-items">Rest-Api</div>
            </div>
            <div className="icon-link-container">
              <div className="link-container">
                <img src={github} className="project-icon" alt="Github icon"/>
                <span>Could not link to this due to bussiness agreement</span>
              </div>
              <div className="link-container">
                <img src={eye} className="project-icon" alt="Eye icon"/>
                <span>Could not link to this due to bussiness agreement</span>
              </div>
            </div>
          </motion.div>
          <motion.div 
            className="project-container" 
            variants={opacityUp(0.7)}
            initial="init" 
            animate="animate" 
            exit="init"
            >
          <img src={ux} className="project-image" alt="Ux - project"></img>
          <h4>UX Design</h4>
          <p>A school assignment to design a couple of form tags from scratch with css. Appearance like Google Material Design</p>
          <div className="dev-container">
            <div className="dev-items">HTML</div>
            <div className="dev-items">CSS</div>
            <div className="dev-items">Javascript</div>
            <div className="dev-items">UX</div>
            <div className="dev-items">Material Design</div>
          </div>
          <div className="icon-link-container">
            <div className="link-container">
              <img src={github} className="project-icon" alt="Github icon"/>
              <span><a href="https://github.com/Nervall/mandatory-uxdesign1" target="_blank" rel="noopener noreferrer">Github Repo</a> </span>
            </div>
            <div className="link-container">
              <img src={eye} className="project-icon" alt="Eye icon"/>
              <span><a href="https://ux-design-154cb.firebaseapp.com/" target="_blank" rel="noopener noreferrer">Visit</a> </span>
            </div>
          </div>
        </motion.div>
        </div>
      </div>
      <div className="three-column-standard">
        <div className="project-main-container">
        <motion.div 
          className="project-container" 
          variants={opacityUp(0.2)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <img src={connect4} className="project-image" alt="Connect 4"></img>
          <h4>Connect 4</h4>
          <p>A simple connect 4 game, made in ReactJS</p>
          <div className="dev-container">
            <div className="dev-items">HTML</div>
            <div className="dev-items">CSS</div>
            <div className="dev-items">Javascript</div>
            <div className="dev-items">ReactJS</div>
          </div>
          <div className="icon-link-container">
            <div className="link-container">
              <img src={github} className="project-icon" alt="Github icon"/>
              <span><a href="https://github.com/Nervall/mandatory-advanced-js4" target="_blank" rel="noopener noreferrer">Github Repo</a> </span>
            </div>
            <div className="link-container">
              <img src={eye} className="project-icon" alt="Eye icon"/>
              <span><a href="https://connect4-6a477.firebaseapp.com/" target="_blank" rel="noopener noreferrer">Visit</a> </span>
            </div>
          </div>
        </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Projects
