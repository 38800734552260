import React from 'react';
import { motion } from 'framer-motion';
import { opacity, opacityUp } from '../utils/framerConfig';
import education from '../../media/education.svg';
import work from '../../media/work.svg';
import './cv.css'

const Cv = () => {
  return (
    <div className="main-container">
      <div className="two-column-standard">
        <motion.div 
          className="cv-icon-container" 
          variants={opacity(0.5)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <img src={work} className="cv-icon" alt="work icon" />
          <h3>Employments</h3>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.5)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Frontend Developer</h3>
          <h4>Company: <a href="https://www.knowit.se" target="_blank" rel="noopener noreferrer">Knowit AB</a></h4>
          <div className="cv-top-container">
            <h4>LIA 2, mars 2020 - maj 2020</h4>
            <div>
            <p>Intern at Knowit. I worked with a administration interface for a project named OmMej. I was responsible for Frontend and did a application with HTML, CSS, ReactJS, chart.js, Redux/ContextApi and Rolebased login (RBAC).</p>
            </div>
          </div>
          <div className="cv-top-container">
            <h4>Master Thesis, jan 2020 - mars 2020</h4>
            <p>I did my master thesis at Knowit. I was looking on a existent application for a customer to Knowit. I updated it with modern environment and I was also looking on the User Experience in the Application. In the project I used both front- and backend. For frontend I used HTML, CSS, Javascript, React. For backend I used Node, Express, MongoDB.</p>
          </div>
          <div className="cv-top-container">
            <h4>LIA 1, sep 2020 - nov 2020</h4>
            <p>Intern at Knowit. Me and a another student did an application to track time from developers in the organization. The application makes Api-calls to Jira and Tempo to get the declared time from different projects and then shows it in our application. We developed the application with HTML, CSS, Javascript, Redux, RBAC and Material Design. In the project we worked with agile working method as Scrum, daylies, Jira and Sprints every second week.</p>
          </div>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.7)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Graphical Designer</h3>
          <h4>Company: <a href="https://www.kraftringen.se" target="_blank" rel="noopener noreferrer">Kraftringen AB</a></h4>
          <div className="cv-top-container">
            <h4>maj 2010 - aug 2018</h4>
            <p>I´ve worked as a Graphic Designer in a marketing team. I did printed matter, illustrations, design for webpages, Social media, Microsoft Office and some animations. I worked with everything from small things to big campaigns. I was in charge for the appearance of cars and signs at Kraftringen.</p>
          </div>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.9)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Customer Coordinator</h3>
          <h4>Company: <a href="https://www.kraftringen.se" target="_blank" rel="noopener noreferrer">Kraftringen</a></h4>
          <div className="cv-top-container">
            <h4>apr 2006 - maj 2010</h4>
            <p>Customer and team coordinator for Kraftringen Service AB. I made marketing material and was responsible for invoicing and coordinate workers on the field. I ended up also responsible for web, forming clothes and in 2010 i was leaving for the marketing team in the corporate group.</p>
          </div>
        </motion.div>
      </div>
      <div className="two-column-standard">
        <motion.div 
          className="cv-icon-container" 
          variants={opacity(0.2)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <img src={education} className="cv-icon" alt="educaton icon" />
          <h3>Educations</h3>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.3)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Frontend Developer</h3>
          <h4>School: <a href="https://www.ecutbildning.se" target="_blank" rel="noopener noreferrer">EC Utbildning</a></h4>
          <h4>EC Utbildning</h4>
          <div className="cv-top-container">
            <h4>sep 2018 - maj 2020</h4>
            <p>Frontend Developer with the latest technologies in Web Development. I had courses in HTML, CSS, Javascript, React, Git, User Experience, REST-Api, NodeJS, CMS and agile working method.</p>
          </div>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.5)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Bachelor in Media Technology</h3>
          <h4>School: <a href="https://www.bth.se" target="_blank" rel="noopener noreferrer">Blekinge Institute of Technology (BTH)</a></h4>
          <div className="cv-top-container">
            <h4>sep 2000 - maj 2003</h4>
            <p>A bachelor in Media Technology. The education was practical and broad. I had courses in programming, graphical art, video, webproduction etc. I did my Master thesis at SVT (Swedish Television) and made a webproduction for kids. I worked as a project leader and made graphic to the website.</p>
          </div>
        </motion.div>
        <motion.div 
          className="cv-container" 
          variants={opacityUp(0.7)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Multimedia Designer</h3>
          <h4>School: <a href="https://www.nti.se" target="_blank" rel="noopener noreferrer">NTI-Skolan</a></h4>
          <div className="cv-top-container">
            <h4>aug 1999 - maj 2000</h4>
            <p>The education was practical and I had courses in software like Photoshop, Illustrator, Indesign, Flash, Dreamweaver etc.</p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Cv;
