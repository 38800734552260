import React from 'react';
import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import { opacity, opacityLeft, opacityRight } from '../utils/framerConfig';
import user from '../../media/user.svg';
import laptop from '../../media/laptop.svg';
import './about.css';

const About = () => {

  const aboutMe = 
    parse 
      (
      `<p>
        Hi! I´m a Frontend developer with 10 years of experience as a Graphic Designer.
        I´ve always had two interests, engineering and design. As a Frontend developer I got both worlds,
        and I just love it. I know that the interplay between those subjects lifts the user experience from
        Ok to Wow, and thats my call to do both userfriendly and nice smooth web applications.
      </p>
      <br>
      <strong>Some of my strengts is:</strong>
      <ul>
        <li>A modern education in the latest frontend technology</li>
        <li>Problemsolver who always want to learn new things</li>
        <li>Graphic eye, who sees the details and is used to wireframing</li>
        <li>Used to do content with target customization</li>
        <li>I´m a stabil person and a appreciated team member</li>
      </ul>
      <br>
      <p>In my sparetime, I like to be with my family and I like sports. I also like to train myself, I train both fotball and floorball. I also appreciate to watch fotball and espacially when my kids playing. I also like to do some joinery on my house.
      `
      )

      const cv = parse(
        `
        <h4>Frontend</h4>
        <ul>
          <li>HTML5</li>
          <li>CSS3</li>
          <li>Javascript</li>
          <li>ReactJS</li>
          <li>Redux/ContextAPI</li>
          <li>Material Design (Material-UI)</li>
          <li>Rest-API</li>
          <li>UI/UX</li>
          <li>Responsive design</li>
          <li>Cockpit (Headless CMS)</li>
        </ul>
        <br>
        <h4>Backend</h4>
        <ul>
          <li>NodeJS</li>
          <li>Express</li>
          <li>MongoDB</li>
          <li>SocketIO</li>
          <li>Firebase</li>
          <li>Google Cloud</li>
        </ul>
        <br>
        <h4>Work processes</h4>
        <ul>
          <li>Project</li>
          <li>Teams</li>
          <li>Agile working method/Scrum</li>
          <li>Jira/Tempo</li>
          <li>Git/Github</li>
        </ul>
        <br>
        <h4>Graphic Design</h4>
        <ul>
          <li>Photoshop</li>
          <li>Illustrator</li>
          <li>Indesign</li>
          <li>Premiere</li>
        </ul>
        `
      )
    

  return (
    <div className="main-container">
      <div className="two-column-standard">
        <motion.div 
          className="cv-icon-container"
          variants={opacity(0.2)}
          initial="init" 
          animate="animate" 
          exit="init" 
          >
          <img src={user} className="cv-icon" alt="work icon" />
          <h3>About me</h3>
        </motion.div>
        <motion.div 
          className="about-container" 
          variants={opacityRight(0.5)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>About Me</h3>
          {aboutMe}
        </motion.div>
      </div>
      <div className="two-column-standard">
      <motion.div 
        className="cv-icon-container" 
        variants={opacity(0.5)}
        initial="init" 
        animate="animate" 
        exit="init"
        >
        <img src={laptop} className="cv-icon" alt="laptop icon" />
        <h3>Tech Software</h3>
      </motion.div>
        <motion.div 
          className="about-container" 
          variants={opacityLeft(0.8)}
          initial="init" 
          animate="animate" 
          exit="init"
          >
          <h3>Tech stack</h3>
          {cv}
        </motion.div>
      </div>
    </div>
  )
}

export default About;