const easing = [0.29, 0.44, 0.35, 0.91]; 

export const changePage = (delay) => {
  return {
    exit: {
      x: 1000
    } 
  }
}

export const opacityUpMin = (delay) => {
  return {
    init: { 
      opacity: 0, y: 20,
      transition: {
        duration: 0.5,
        ease: easing
      }
    },
    animate: {
      opacity: 1, y: 0, 
      transition: {
        delay: delay,
        duration: 0.5,
        ease: easing
      }

    }
  }
};

export const opacityDownMin = (delay) => {
  return {
    init: { 
      opacity: 0, y: -20,
      transition: {
        duration: 0.5,
        ease: easing
      }
    },
    animate: {
      opacity: 1, y: 0, 
      transition: {
        delay: delay,
        duration: 0.5,
        ease: easing
      }

    }
  }
};

export const opacity = (delay) => {
  return {
    init: { opacity: 0, transition: { duration: 0.5, ease: easing } },
    animate: {
      opacity: 1,
      transition: {
        duration: 2,
        ease: easing,
        delay: delay
      }
    }
  }
};

export const imgOpacity = (delay) => {
  return {
    init: { 
      y: 50,
      opacity: 0,  
      transition: { 
        duration: 0.5,
        ease: easing } },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: easing,
        delay: delay
      }
    },
    /* exit: {
      opacity: 0, 
      scale: 0,
      transition: {
        duration: 1,
        ease: easing,
      }
    } */
  }
};

export const opacityUp = (delay) => {
  return {
    init: { y: 100, opacity: 0, transition: { duration: 0.5, ease: easing } },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: easing,
        delay: delay
      }
    }
  }
};

export const opacityLeft = (delay) => {
  return {
    init: { x: 100, opacity: 0, transition: { duration: 0.5, ease: easing } },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: easing,
        delay: delay
      }
    }
  }
};

export const opacityRight = (delay) => {
  return {
    init: { x: -100, opacity: 0, transition: { duration: 0.5, ease: easing } },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: easing,
        delay: delay
      }
    }
  }
};